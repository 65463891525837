@import "src/theme/variables";

.container {
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
}

.number-wrapper {
  background: #fff;
  border: 10px solid #fff;
  border-radius: 50%;
  margin-top: -5px;
}

.number {
  width: 35px;
  height: 35px;
  background: $palette-green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.text {
  padding-left: 10px;
}
