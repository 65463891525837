@import "src/theme/variables";

.container {
  padding-top: 40px;
}

.row {
  display: flex;
  margin-bottom: 20px;
}

.column-1,
.column-2,
.column-3,
.column-4 {
  flex: 0 0 50%;
  position: relative;
}

.column-2 {
  padding-bottom: 30px;
}

.image {
  max-width: 80%;
  display: block;
  margin: auto;
}

.image-1 {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 80%;
  max-height: 90%;
  margin: 0 auto;
}

.screenshot {
  margin: 0 0 20px 63px;
  position: relative;
}

.image-2 {
  max-width: 100%;
  border: 1px solid #eee;
  border-radius: 3px;
}

.image-1-sm,
.image-2-sm {
  display: none;
}

.image-4 {
  margin-bottom: 30px;
}

.line {
  position: absolute;
  border: 1px solid $palette-green;
  z-index: -1;
}

.line-1 {
  bottom: 0;
  left: 28px;
  right: -28px;
}

.line-2 {
  top: 100%;
  bottom: -20px;
  left: 28px;
}

.line-3 {
  top: 0;
  bottom: 0px;
  left: 27px;
}

.line-4 {
  top: 23px;
  left: -27px;
  right: 100%;
}

.cell {
  position: relative;
}

@media (max-width: 776px) {
  .line {
    display: none;
  }

  .column-1 {
    flex: 0 0 40%;
  }

  .column-2 {
    flex: 0 0 60%;
  }
}

@media (max-width: 600px) {
  .container {
    padding-top: 0;
  }

  .row {
    flex-direction: column;
  }

  .column-4 {
    display: flex;
    flex-direction: column-reverse;
  }

  .image {
    max-width: 100%;
    margin: 30px 0;
  }

  .image-1 {
    display: none;
  }

  .image-1-sm,
  .image-2-sm, {
    display: block;
  }

  .image-1-sm {
    margin: 30px 0 55px 0;
  }

  .image-2-sm {
    margin: 10px 0;
    max-width: 100%;
    border: 1px solid #eee;
    border-radius: 3px;
  }

  .image-2 {
    display: none;
  }

  .image-3 {
    margin-bottom: 65px;
  }
}
