@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #555;
    font-size: 16px;
    line-height: 1.5;
}

h1 {
    font-weight: 500;
    margin-bottom: 3rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 400;
}

h3 {
    font-size: 1.15rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
}
