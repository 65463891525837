.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.img {
  display: block;
  max-width: 100%;
  margin-right: 3px;
}
