@import "src/theme/variables.scss";

.language-switch {
  display: flex;
  margin: 0 -5px;
}

.language-switch__item {
  margin: 0 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: $palette-gray;
  cursor: pointer;
}

.language-switch__item--active {
  color: $palette-green;
  border-bottom: 2px solid $palette-green;
}
