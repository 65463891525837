@import "src/theme/variables";

.container {
  padding-top: 40px;
}

.row {
  display: flex;
}

.column-1 {
  flex: 0 0 23%;
}

.column-2 {
  flex: 0 0 47%;
}

.column-3 {
  flex: 0 0 30%;
}

// cells

.cell-1,
.cell-2,
.cell-3,
.cell-4 {
  position: relative;
}

.cell-1 {
  padding: 0 20px;
  min-height: 175px;
}

.cell-2 {
  padding: 0 60px 45px 20px;
}

.cell-3 {
  display: flex;
  margin-left: -50px;
  padding-top: 15px;
  min-height: 170px;
}

.cell-4 {
  padding: 15px 60px 0 100px;
}

// lines

.line-1,
.line-2,
.line-3,
.line-4,
.line-5,
.line-6,
.line-7 {
  position: absolute;
  z-index: -1;
  border: 1px solid $palette-green;

}

.line-1 {
  left: 47px;
  top: 0;
  bottom: 0;
}

.line-2 {
  top: 25px;
  right: 15px;
  width: 40px;
}

.line-3 {
  top: -15px;
  left: 27px;
  right: -80px;
}

.line-4 {
  top: -15px;
  bottom: 30px;
  left: 27px;
}

.line-5 {
  top: -15px;
  height: 135px;
  right: -80px;
  transform: translateY(-100%);
}

.line-6 {
  top: 37px;
  left: 60px;
  width: 50px;
}

.line-7 {
  top: 37px;
  right: -40px;
  width: 90px;
}



.image {
  width: 100%;
  margin-bottom: 70px;
}

.screenshot {
  border: 1px solid #eee;
  border-radius: 3px;
  margin-top: 75px;
}

.column-1 .image:last-child,
.column-3 .image:last-child{
  width: 130%;
  margin-top: 25px;
}
