@import "src/theme/variables";

.container {
  background: #f4f4f3;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;
}

.content {
  display: flex;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.logo{
  position: absolute;
  top: 0;
  left: 20px;
  width: 100px;
}

.tree {
  position: relative;
  display: none;
  flex: 0 0 0;
  height: 0;
  background: linear-gradient(135deg, $palette-light-green, $palette-green);
}

.aside {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.language {
  padding: 30px 30px 0 0;
  align-self: flex-end;
}

.navigation {
  padding: 10px 30px 0 0;
  align-self: flex-end;
}

.message {
  padding: 30px 48px 50px;
  letter-spacing: .25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #646977;
}



@media (min-width: 776px) {
  .tree {
    display: block;
    height: 100vh;
    flex: 0 0 40%;
  }

  .aside {
    flex: 0 0 60%;
  }

  .message {
    flex-grow: 1;
  }

  .logo {
    position: absolute;
    top: 35px;
    left: 15px;
    width: 160px;
  }
}

@media (min-width: 1024px) {
  .tree {
    flex: 0 0 50%;
  }

  .aside {
    flex: 0 0 50%;
  }
}

@media (min-width: 1216px) {
  .tree {
    flex: 0 0 55%;
  }

  .aside {
    flex: 0 0 45%;
  }
}
