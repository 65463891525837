.concept-small {
  display: none;
}

.concept-large {
  display: block;
}

@media (max-width: 1100px) {
  .concept-small {
    display: block;
  }

  .concept-large {
    display: none;
  }
}