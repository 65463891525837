@import "src/theme/variables";

.button {
  display: block;
  width: 100%;
  padding: 14px;
  border-radius: 3px;
  border: none;
  margin: 32px 0;
  background: linear-gradient(135deg, $palette-light-green, $palette-green);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  font-family: inherit;
}
