.container {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.title {
  font-size: 32px;
  color: #222;
  font-weight: 300;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;
  margin-top: 0;
  margin-bottom: 1rem;
}

@media (max-width: 776px) {
  .title {
    font-size: 28px;
  }
}
