.container {
  padding: 50px 0;
  overflow: hidden;
}

.content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 15px;
}

.grey {
  background: #fbfbfb;
}

.border-top {
  border-top: 1px solid #ececec;
}

.border-bottom {
  border-bottom: 1px solid #ececec;
}
