@import "src/theme/variables";

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 32px;
}

.text {
  margin: 0 0 30px 0;
  line-height: 1.75;
  font-size: 18px;
  font-weight: 500;
}

.link {
  color: $palette-dark-green;
  font-size: 15px;
  font-weight: 500;
  align-self: center;
}
