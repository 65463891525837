@import "src/theme/variables";

.container a {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 0 0 24px;
  font-size: 14px;
  padding-bottom: 4px;
  cursor: pointer;
}

.container a:hover {
  color: $palette-dark-green;
  border-bottom: 3px solid $palette-dark-green;
}
