@import "src/theme/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.text {
  flex: 0 0 100%;
  margin-bottom: 20px;
}

.persons {
  flex: 0 0 100%;
}

.persons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
}

.person {
  position: relative;
  padding: 0 5px;
  min-width: 230px;
  max-width: 280px;
  margin-bottom: 40px;
}

.photo {
  width: 100%;
}

.title {
  font-weight: 700;
}

.link {
  color: $palette-dark-green;
  font-weight: 700;
}

@media (min-width: 1024px) {
  .container {
    flex-wrap: nowrap;
  }

  .text {
    flex: 0 0 60%;
    padding-right: 20px;

  }

  .text > div {
    text-align: left;
  }

  .persons {
    flex: 0 0 40%;
    padding-left: 20px;
    flex-wrap: nowrap;
  }
}
